
riot.tag2('editmode', '<div if="{editmode || opts.or}"><yield></yield></div>', '', '', function(opts) {
this.editmode = false;

this.on('mount', function() {
  return document.addEventListener('duocms:updated', this.modechange.bind(this));
});

this.on('unmount', function() {
  return document.removeEventListener('duocms:updated', this.modechange.bind(this));
});

this.modechange = (function(_this) {
  return function() {
    var ref, ref1;
    console.log('modechange', (ref = window.Duocms) != null ? ref.editmode : void 0);
    if ((ref1 = window.Duocms) != null ? ref1.editmode : void 0) {
      _this.root.setAttribute('style', 'display:block');
      _this.editmode = true;
    } else {
      _this.root.setAttribute('style', 'display:none');
      _this.editmode = false;
    }
    return _this.update();
  };
})(this);
});