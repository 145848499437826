require('../mixins/i18n.coffee')();
riot.tag2('i18n', '<span name="localised"></span><span name="original" class="original"><yield></yield></span>', 'i18n .original, [riot-tag="i18n"] .original { display: none; }', '', function(opts) {
this.mixin('i18n');

this.on('mount update', function(event) {
  this.i18n.on('update', (function(_this) {
    return function() {
      return _this.update();
    };
  })(this));
  return this.localised.innerHTML = this.i18n.localise(this.original.innerHTML || opts.content);
});

this.on('unmount', function() {
  return this.i18n.off('update');
});
});