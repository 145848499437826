
riot.tag2('dateformat', '<span>{text}</span>', '', '', function(opts) {
var Formatter;

Formatter = require('../utils/dateformat.coffee');

this.on('update', (function(_this) {
  return function() {
    var dt;
    dt = new Formatter(opts.date);
    return _this.text = dt.format(opts.format);
  };
})(this));
});