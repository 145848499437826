
riot.tag2('navigation', '<yield></yield>', '.growing { -moz-transition: 0.5s all; -ms-transition: 0.5s all; -o-transition: 0.5s all; -webkit-transition: 0.5s all; -webkit-transition: 0.5s all; -moz-transition: 0.5s all; -o-transition: 0.5s all; -ms-transition: 0.5s all; transition: 0.5s all; overflow-y: hidden; } .navbar-collapse.collapse { max-height: 0px; overflow: hidden; display: block; } .navbar-collapse.collapse.in { max-height: 1000px; }', '', function(opts) {
this.show = false;

this.toggle = (function(_this) {
  return function() {
    _this.show = !_this.show;
    return _this.update();
  };
})(this);
});