
riot.tag2('dropdown', '<yield></yield>', 'dropdown { display: block; position: relative; }', '', function(opts) {

/*
  On desktop, hover shows menu
  On Mobile, touchstart shows menu, but clicking again after open doesn't preventDefault so navigates
  Any submenus need target setting, so they don't get intercepted by toggle event
 */
this.show = false;

this.on('mount', function() {
  this.root.addEventListener('click', this.swap);
  return this.root.addEventListener('touchstart', this.swap);
});

this.on('unmount', function() {
  this.root.removeEventListener('click', this.swap);
  this.root.removeEventListener('touchstart', this.swap);
  return document.removeEventListener('click', this.hide);
});

this.hide = (function(_this) {
  return function(e) {
    _this.show = false;
    document.removeEventListener('click', _this.hide);
    document.removeEventListener('touchstart', _this.hide);
    _this.root.className = _this.root.className.replace(/ open/, '');
    _this.update();
    return true;
  };
})(this);

this.swap = (function(_this) {
  return function(e) {
    var ref, ref1;
    if (((ref = e.target) != null ? (ref1 = ref.attributes) != null ? ref1.target : void 0 : void 0) != null) {
      return true;
    }
    if (_this.show || e.touches) {
      e.stopPropagation();
      if (!_this.show) {
        e.preventDefault();
      }
    }
    _this.show = !_this.show;
    if (_this.show) {
      [].slice.call(document.querySelectorAll("[riot-tag='dropdown']")).forEach(function(dropdown) {
        var ref2;
        if (dropdown !== _this.root) {
          return dropdown != null ? (ref2 = dropdown._tag) != null ? ref2.hide() : void 0 : void 0;
        }
      });
      _this.root.setAttribute("class", _this.root.className += " open");
      setTimeout(function() {
        return document.addEventListener('click', _this.hide);
      });
    } else {
      _this.hide();
    }
    return true;
  };
})(this);
});