
riot.tag2('alert', '<div role="alert" if="{exists}" class="alert {opts.bsstyle ? \'alert-\'+opts.bsstyle : \'alert-info\'} fade {in:showing}"> <button if="{opts.onclose}" type="button" data-dismiss="alert" aria-label="Close" onclick="{close}" class="close"><span aria-hidden="true">&times;</span></button><yield></yield> </div>', '', '', function(opts) {
this.showing = true;

this.exists = true;

this.close = (function(_this) {
  return function() {
    if ((opts.onclose != null) && typeof opts.onclose === "function") {
      opts.onclose();
    }
    _this.showing = false;
    return setTimeout(function() {
      _this.exists = false;
      return _this.update();
    }, 250);
  };
})(this);
});