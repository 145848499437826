require('./modal');
riot.tag2('gallery', '<yield></yield> <modal name="slidemodal" bssize="lg" esckey="{true}"> <div class="gallery-inner"><img each="{image,index in parent.images}" riot-src="{image}" class="img-responsive {active:index==parent.parent.index}"></div> <button onclick="{hide}" class="btn-close"><i class="fa fa-times"></i></button> <button onclick="{parent.prev}" class="control prev"><i class="fa fa-angle-left"> </i></button> <button onclick="{parent.next}" class="control next"><i class="fa fa-angle-right"></i></button> </modal>', 'gallery { display: block; overflow: hidden; margin-bottom: 50px; } .gallery-inner { position: relative; } .gallery-inner img { margin: 0 auto; -webkit-transition: opacity 1s; -moz-transition: opacity 1s; -o-transition: opacity 1s; -ms-transition: opacity 1s; transition: opacity 1s; position: absolute; top: 0; left: 0; right: 0; opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); } .gallery-inner img.active { opacity: 1; -ms-filter: none; filter: none; position: static; } .modal .btn-close { position: absolute; right: 15px; top: 15px; border-width: 0; background: rgba(0,0,0,0.1); color: #fff; } .modal-body button.control { position: absolute; top: 50%; width: 44px; height: 44px; background-color: rgba(0,0,0,0.1); color: #fff; font-size: 35px; line-height: 40px; border: 0px solid #fff; margin-top: -22px; } .modal-body .next { right: 15px; } .modal-body .prev { left: 15px; }', '', function(opts) {
this.index = 0;

this.oldimage = 0;

this.slideclass = ['active'];

this.on('mount', function() {
  return this.images = [].slice.call(this.root.querySelectorAll('img')).map((function(_this) {
    return function(img, index) {
      return img.src.replace(/\/rs-[0-9]+x[0-9]+[a-z]?\.[a-z]+\/?$/, '').replace(window.location.origin, '');
    };
  })(this));
});

this.show = (function(_this) {
  return function(e) {
    var img, ref, ref1;
    img = (ref = e.target.src) != null ? (ref1 = ref.replace(/\/rs-[0-9]+x[0-9]+[a-z]?\.[a-z]+\/?$/, '')) != null ? ref1.replace(window.location.origin, '') : void 0 : void 0;
    _this.images.forEach(function(image, index) {
      if (image === img) {
        return _this.index = index;
      }
    });
    return _this.tags.slidemodal.show();
  };
})(this);

this.next = (function(_this) {
  return function() {
    _this.oldimage = _this.index;
    _this.slideclass[_this.oldimage] = 'active left';
    _this.index++;
    if (_this.index > _this.images.length - 1) {
      _this.index = 0;
    }
    _this.slideclass[_this.index] = 'next';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.index] = 'next left';
      return _this.update();
    }, 1);
    return setTimeout(function() {
      _this.slideclass[_this.index] = 'active';
      _this.slideclass[_this.oldimage] = '';
      return _this.update();
    }, 700);
  };
})(this);

this.prev = (function(_this) {
  return function() {
    _this.oldimage = _this.index;
    _this.slideclass[_this.oldimage] = 'active right';
    _this.index--;
    if (_this.index < 0) {
      _this.index = _this.images.length - 1;
    }
    _this.slideclass[_this.index] = 'next';
    _this.update();
    return setTimeout(function() {
      _this.slideclass[_this.index] = 'active';
      _this.slideclass[_this.oldimage] = '';
      return _this.update();
    }, 700);
  };
})(this);
});